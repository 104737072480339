<template>
  <div class="groupsList">
    <div class="card" v-if="me.roles[0].name == 'admin' || me.roles[0].name == 'moderator'">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="col-sm-3">
          <label for="">Фильтрация по сотрудникам</label>
          <a-select
              mode="multiple"
              placeholder="Выберите сотрудника"
              v-model="dataFilter.creator_id"
              optionLabelProp="label"
              optionFilterProp="label"
              style="width: 300px"
              @change="applyFilter"
            >
            <a-select-option v-for="user in users" :key="user.id" :value="user.id" :label="user.name">
              {{user.name}}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <mini-loader v-if="load" />
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="text-dark">Группы</h5>
        <a @click="createModal">
          <span class=" p-1">
            <button type="button" class="btn btn-success">Добавить
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
                <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
              </svg>
            </button>
          </span>
        </a>
      </div>
      <table class="table table-striped" id="groupsList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">ID <span style="color: red">(для excell)</span></th>
            <th scope="col">Наименование</th>
            <th scope="col">Создатель</th>
            <th scope="col">Количество абонентов</th>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td>{{value.id}}</td>
            <td>{{value.title}}</td>
            <td v-if="value.creator">{{value.creator.name}}</td> <td v-else>--</td>
            <td v-if="value.abonents_count">{{value.abonents_count}}</td> <td v-else>--</td>
            <td>
              <a  @click="showModal(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editModal(value)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteModal(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
    </div>

    <!-- Show Create Modal -->
    <a-modal v-model="visibleCreateModal" title="Добавить группу"  footer="">
      <div class="form-group">
        <label for="exampleInputEmail1">Название группы</label>
        <div>
          <input type="email" class="form-control" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}" placeholder="Название группы">
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </div>
      </div>
      <div class="d-flex">
        <button type="submit" class="ml-auto btn btn-primary" @click="storeGroup">Добавить</button>
      </div>
    </a-modal>

    <!-- Show Edit Modal -->
    <a-modal v-model="visibleEditModal" title="Добавить группу"  footer="">
      <div class="form-group">
        <label for="exampleInputEmail1">Название группы</label>
        <div>
          <input type="email" class="form-control" v-model="item.title" :class="{'is-invalid': validateErrors.title}" placeholder="Название группы">
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </div>
      </div>
      <div class="d-flex">
        <button type="submit" class="ml-auto btn btn-primary" @click="updateGroup">Изменить</button>
      </div>
    </a-modal>

    <!-- Delete modal -->
    <a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
      <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
    </a-modal>

  </div>
</template>

<script>
import axios from 'axios'

const data = '';
export default {
  data() {
    return {


      dataFilter: {
        creator_id: [],
        page: 1,
      },

      ind: -10,
      load: true,
      visible: false,
      visibleCreateModal: false,
      visibleEditModal: false,
      visibleDeleteModal: false,

      item: {
        title: '',
      },
      newItem: {
        title: '',
      },

      data,
      users: [],

      currentPage: 1,
      totalResults: 0,
      old_height: 0,
      last_page: 1,
      load: true,
      validateErrors: []

    }
  },
  mounted(){
    this.load = true;
    if(this.me.roles[0].name == 'admin' || this.me.roles[0].name == 'moderator'){
      this.getUsers();
    }
    this.getGroups();

    window.onscroll = () => {
        let height = document.getElementById('groupsList').clientHeight;
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            this.dataFilter.page = this.data.length/this.$store.state.paginateCount + 1;
            if(this.dataFilter.page <= this.last_page){
              this.getGroupsPaginate();
            }
          }
        }
    };

  },
  methods: {

    storeGroup(){
      this.load = true;
      axios.post('/employee/mailing/email/groups', this.newItem).then((response)  =>  {
        this.visibleCreateModal = false
        this.data = this.data.concat(response.data)
        this.newItem.title = ''
        this.totalResults++;

        this.$message.success(
          'Успешно добавлено',
          3
        );
      }).catch((error)  =>  {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors;
          this.$message.error(
            error.response.data.message,
            3
          );
        }
      }).finally(() =>  {this.load = false;});
    },
    updateGroup(){
      this.load = true;
      axios.put('/employee/mailing/email/groups/'+this.item.id, this.item).then((response)  =>  {
        if(response.status == 200){
          this.$message.success(
            'Успешно сохранено',
            3
          );
          this.visibleEditModal = false;
        }
      }).catch((error)  =>  {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors;
          this.$message.error(
            error.response.data.message,
            3
          );
        }else if(error.response.status == 404 || error.response.status == 403){
          this.$message.error(
            error.response.data.error,
            3
          );
        }
      }).finally(() => {this.load = false;})
    },
    destroyItem(){
      this.load = true;
      axios.delete('/employee/mailing/email/groups/'+this.item.id).then((response)  =>  {
        if(response.status == 200){
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.ind, 1)
          this.totalResults--;
          this.visibleDeleteModal = false
        }
      }).catch((error)  =>  {
        if(error.response.status == 404 || error.response.status == 403){
          this.$message.error(
            error.response.data.error,
            3
          );
        }else if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }
      }).finally(() =>  {this.load = false;})
    },

    showModal(value){

    },
    createModal(){
      this.visibleCreateModal = true;
    },
    editModal(value){
      this.item = value;
      this.visibleEditModal = true;
    },
    deleteModal(index, value){
      this.item = value;
      this.ind = index;
      this.visibleDeleteModal = true;
    },

    applyFilter(){
      this.getGroups();
    },
    getGroups(){
      this.load = true;
      axios.get('/employee/mailing/email/groups', {params: this.dataFilter}).then((response) =>  {
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.$message.error(
            'Группы не найдены',
            3
          );
        }
      }).catch((error)  =>  {

      }).finally(() =>  {this.load = false;});
    },
    getGroupsPaginate(){
      this.load = true;
      axios.get('/employee/mailing/email/groups', {params: this.dataFilter}).then((response) =>  {
        if(response.status == 200){
          this.data = this.data.concat(response.data.data);
          this.totalResults = response.data.total;
          this.last_page = response.data.last_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.last_page = 0;
          this.$message.error(
            'Группы не найдены',
            3
          );
        }
      }).catch((error)  =>  {

      }).finally(() =>  {this.load = false;});
    },
    getUsers(){
      axios.get('/users/admin-moder-oper/get').then((response) => {
        if(response.status == 200){
          this.users = response.data;
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {})
    }
  },
  computed: {
    me () {
      return JSON.parse(localStorage.me)
    },
  },

}
</script>

<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
</style>
